@import "../../Constants.scss";

.AnswerCard {
    box-sizing: border-box;
    min-width: $card-size;
    height: 100%;
    padding-left: $card-padding;
    padding-right: $card-padding;
    color: black;

    .Text {
        align-self: center;
        font-size: $card-font-size;

        &.Animation {
            animation: bounce 2s ease-in-out infinite;
            animation-delay: calc(0.2s + .1s * var(--index));
        }

        @keyframes bounce {

            0%,
            20% {
                transform: translateY(0);
            }

            10% {
                transform: translateY(-20px);
            }
        }
    }

    &.IsOver {
        background-color: lightgrey;
    }
}