@import "../../Constants.scss";

.Game {
    text-align: center;
    flex: 1;
    padding: 20px;
    display: flex;

    &.IsOver {
        background-color: lightgrey;
    }

    .CardContainer {
        flex: 1;
        display: flex;
        gap: $row-gap;
        flex-direction: column;

        .PhonemePanel {
            height: $word-panel-height;
            display: flex;
            gap: $min-card-gap;
            justify-content: center;

            .Phonemes {
                min-width: $card-size;
                display: flex;
                gap: $components-card-gap;
            }
        }

        .AnswerPanel {
            display: flex;
            flex-direction: column;
            align-items: center;

            .Answers {
                height: $card-size;
                box-sizing: border-box;
                display: flex;
                border: $border-thickness solid lighten(lightslategrey, 30%);
                border-radius: $border-radius;
                background-color: lightslategrey;
                box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
            }

            .Indicators {
                display: flex;

                .Indicator {
                    box-sizing: border-box;
                    min-width: $card-size;
                    height: $indicator-height;

                    &.Left {
                        .Inner {
                            box-sizing: border-box;
                            width: 50%;
                            height: 100%;
                            margin-left: auto;
                            border-left: $indicator-thickness dashed $indicator-color;
                            border-bottom: $indicator-thickness dashed $indicator-color;
                            border-bottom-left-radius: $indicator-radius;
                        }
                    }

                    &.Right {
                        .Inner {
                            box-sizing: border-box;
                            width: 50%;
                            height: 100%;
                            margin-right: auto;
                            border-right: $indicator-thickness dashed $indicator-color;
                            border-bottom: $indicator-thickness dashed $indicator-color;
                            border-bottom-right-radius: $indicator-radius;
                        }
                    }

                    &.Between {
                        border-bottom: $indicator-thickness dashed $indicator-color;
                    }
                }
            }
        }

        .SummaryPanel {
            flex: 1;
            display: flex;
            justify-content: center;

            .Outcome {
                min-width: $min-summary-width;
                height: $summary-height;
                padding-left: 20px;
                padding-right: 20px;
                background-color: orange;
                display: flex;
                border: $border-thickness solid;
                border-radius: $border-radius;
                box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

                .Text {
                    align-self: center;
                    flex: 1;
                    font-size: $summary-font-size;

                    .WordAnswer {
                        font-weight: bold;
                    }
                }

                &.Correct {
                    visibility: visible;
                    background-color: lightgreen;
                    border-color: lighten(lightgreen, 10%);
                    cursor: pointer;
                }

                &.Incorrect {
                    visibility: visible;
                    background-color: lightcoral;
                    border-color: lighten(lightcoral, 10%);
                    cursor: no-drop;
                }

                &.Incomplete {
                    visibility: hidden;
                }
            }
        }
    }

    .Toolbox {
        width: $tools-size;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}