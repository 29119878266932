@use "sass:math";
@import "../../Constants.scss";

// TODO - Add underlay div that has shadow which is hidden when card starts to drag
.WordCardContainer {
    height: $card-size;

    .WordCard {
        box-sizing: border-box;
        min-width: $card-size;
        height: $card-size;
        flex: 1;
        background-color: cornflowerblue;
        border: $border-thickness solid lighten(cornflowerblue, 25%);
        border-radius: $border-radius;
        color: black;
        display: flex;
        // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

        &.IsLeft {
            min-width: math.div($card-size, 2);
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            >.Text {
                border-right: medium dashed darkslategrey;
            }
        }

        &.IsRight {
            min-width: math.div($card-size, 2);
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            >.Text {
                border-left: medium dashed darkslategrey;
            }
        }

        .Text {
            padding-left: $card-padding;
            padding-right: $card-padding;
            flex: 1;
            align-self: center;
            font-size: $card-font-size;
            height: 100%;
        }

        &.IsOver {
            background-color: lightgrey;
        }

        &.Hidden {
            visibility: hidden;
        }
    }
}