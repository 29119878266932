@use "sass:math";

// $word-panel-height: 300px;
// $card-size: 150px;
// $card-padding: 10px;
// $card-font-size: 80px;
// $menu-font-size: 50px;
// $summary-font-size: 30px;
// $min-card-gap: 10px;
// $menu-card-gap: 20px;
// $components-card-gap: 20px;
// $row-gap: 30px;
// $tools-size: 60px;
// $border-thickness: 7.5px;
// $border-radius: 15px;
// $indicator-thickness: 4px;
// $indicator-color: darkslategrey;
// $indicator-height: 30px;
// $indicator-radius: math.div($indicator-height, 2);

$word-panel-height: 32vh;
$card-size: 16vh;
$card-padding: 2vh;
$card-font-size: 9vh;
$menu-font-size: 4.5vh;
$summary-font-size: 4vh;
$min-summary-width: 50vh;
$summary-height: 9vh;
$min-card-gap: 2vh;
$menu-card-gap: 4vh;
$components-card-gap: 2vh;
$row-gap: 4vh;
$tools-size: 60px;
$border-thickness: 1vh;
$border-radius: 1.6vh;
$indicator-thickness: 0.5vh;
$indicator-color: darkslategrey;
$indicator-height: 3vh;
$indicator-radius: math.div($indicator-height, 2);

.HoverEffect {
    transition: transform .2s;

    &:hover {
        transform: scale(1.05);
    }
}