@import "../../Constants.scss";

.StageMenu {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $menu-card-gap;
    margin: 20px;

    .StageTitle {
        box-sizing: border-box;
        width: 100%;
        min-height: 100px;
        background-color: lightgreen;
        border: $border-thickness solid lighten(cornflowerblue, 25%);
        border-radius: $border-radius;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        display: flex;
    }

    .StageNames {
        display: flex;
        flex-wrap: wrap;
        gap: $menu-card-gap;
        justify-content: center;

        .StageName {
            min-width: 200px;
            min-height: 100px;
            padding-left: 20px;
            padding-right: 20px;
            background-color: cornflowerblue;
            border: $border-thickness solid lighten(cornflowerblue, 25%);
            border-radius: $border-radius;
            display: flex;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }
    }

    .Text {
        flex: 1;
        align-self: center;
        text-align: center;
        font-size: $menu-font-size;
    }
}