@import "../../Constants.scss";

.FocusMenu {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $menu-card-gap;
    margin: 20px;

    .FocusTitle {
        box-sizing: border-box;
        width: 100%;
        min-height: 100px;
        background-color: lightgreen;
        border: $border-thickness solid lighten(cornflowerblue, 25%);
        border-radius: $border-radius;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        display: flex;
        padding-left: 10px;
        padding-right: 10px;

        .Icon {
            width: 60px;
            justify-self: start;
        }
    }

    .FocusNames {
        display: flex;
        flex-wrap: wrap;
        gap: $menu-card-gap;
        justify-content: center;

        .FocusName {
            min-width: 200px;
            min-height: 100px;
            padding-left: 20px;
            padding-right: 20px;
            background-color: cornflowerblue;
            border: $border-thickness solid lighten(cornflowerblue, 25%);
            border-radius: $border-radius;
            display: flex;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
            cursor: pointer;

            &.All {
                background-color: lightcoral;
            }
        }
    }

    .Text {
        flex: 1;
        align-self: center;
        text-align: center;
        font-size: $menu-font-size;
    }
}